import { IonCol, IonGrid, IonRow } from '@ionic/react';
import Button from '@material-ui/core/Button';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import OpenLink from '../../assets/avatar/open-link.svg';
import Reliable from '../../assets/avatar/reliable.svg';
import Standard from '../../assets/avatar/standard.svg';
import bannerImg from '../../assets/bg/header.png';
import partnerBg from '../../assets/bg/partnerBg.png';
import bannerInnerImg from '../../assets/header-image.png';
import Partner from '../../assets/icon/partner-icon.svg';
import logo from '../../assets/logo/j-arc-logo-white.svg';
import PartnerLogos from '../../assets/logo/partner-logos.png';
import PartnerLogosMobile from '../../assets/Mobile/partner-logos-mobile.png';
import ArchiveDetails from '../../components/ArchiveDetails/ArchiveDetails';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import { getArchives } from '../../util/API';
import {useTranslations} from '../../util/useTranslation';
import { HomePage } from './Homestyle';
import { HashLink } from 'react-router-hash-link';


const Home: React.FC = () => {
  const [archiveData, setArchiveData] = React.useState([])
  const [hm] = useTranslations('home');
  let history = useHistory();

  useEffect(() => {
    const fetchArchiveData = async () => {
      const results: any = await getArchives();
      console.log("RESULTS: " + JSON.stringify(results));
      if (results.data.data.length) {
        setArchiveData(results.data.data);
      }
    };
    fetchArchiveData();
  }, []);

  return (
    <div className='main' >
       <div className="ion-hide-md-up">
        <Header />
      </div>
      <HomePage>
        <div className='banner-img d-flex' style={{ backgroundImage: `url(${bannerImg})` }}>
          <div className='container d-flex'>
            <IonGrid>
            <div className="ion-hide-md-down home-header-container">
              <Header logo={logo}/>
            </div>  
              <IonRow>
                <IonCol size='12' sizeXl='6' sizeMd='12' sizeSm='6'>
                  <div>
                    <div className='banner-content-wrap '>
                      
                      
                      <h2 className='preserve-and-provide'>{hm('header.headtitle')}</h2>
                    </div>
                    
                  </div>
                </IonCol>
                <IonCol >
                  <div>
                    <img src={bannerInnerImg} className='banner-inner-img' />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
        <div className='container'>
          <div className='discription'>
            <div className='row'>
              <div className='d-flex'>
                <IonGrid>
                  <IonRow>
                    <IonCol size='12' sizeMd='12'>
                      <h3 className='left'>{hm('description')}</h3>
                    </IonCol>
                    
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='padding-bottom-110-and-21'>
            <IonGrid>
              <IonRow className='d-flex' >
                <IonCol size='12' sizeMd='4'>
                  <div className='d-flex avatar'>
                    <img src={Reliable} alt='Reliable Icon' className='avatar-img' />
                    <div className='avatar-write'>
                      <h3 className='avatar-head'>{hm('avatar.title_reliable')}</h3>
                      <p className='avatar-discription'>{hm('avatar.discription_reliable')}</p>
                    </div>
                  </div>
                </IonCol>
                <IonCol size='12' sizeMd='4'>
                  <div className='d-flex avatar'>
                    <img src={Standard} alt='Standard Icon' className='avatar-img' />
                    <div className='avatar-write'>
                      <h3 className='avatar-head'>{hm('avatar.title_standard')}</h3>
                      <p className='avatar-discription'>{hm('avatar.discription_standard')}</p>
                    </div>
                  </div>
                </IonCol>
                <IonCol size='12' sizeMd='4'>
                  <div className='d-flex avatar'>
                    <img src={OpenLink} alt='Open Link Icon' className='avatar-img' />
                    <div className='avatar-write'>
                      <h3 className='avatar-head'>{hm('avatar.title_open')}</h3>
                      <p className='avatar-discription'>{hm('avatar.discription_open')}</p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
        <div className='container' style={{ paddingBottom: '17vh' }}>
          <span className='title'>{hm('explore')}</span>

          {/* Archives Data */}
          <ArchiveDetails data={archiveData} />

          <div className='d-flex ion-justify-content-center'>
            <a onClick={() => history.push(`archives`)}>
              <Button
                className='exploreMore'
                variant='contained'
                color='primary'
                endIcon={<ArrowRightAltIcon />}
              >
                {hm('view_button')}
              </Button>
            </a>
          </div>
        </div>
        <div className='container'>
         <span className='title'>{hm('onboarding.title')}</span>

          <div className='padding-bottom-110-and-21'>
            <IonGrid>
              <IonRow className='d-flex' >
                <IonCol size='12' sizeMd='3'>
                  <div className='d-flex avatar'>
                    <div className='avatar-write'>
                      <h3 className='avatar-head'><HashLink smooth to="/how-it-works#page1">{hm('onboarding.title1')}</HashLink></h3>
                      <p className='avatar-discription'>{hm('onboarding.description1')}</p>
                    </div>
                  </div>
                </IonCol>
                <IonCol size='12' sizeMd='3'>
                  <div className='d-flex avatar '>
                    <div className='avatar-write'>
                      <h3 className='avatar-head'><HashLink smooth to="/how-it-works#page2">{hm('onboarding.title2')}</HashLink></h3>
                      <p className='avatar-discription' dangerouslySetInnerHTML={{__html: hm('onboarding.description2')}}></p>
                    </div>
                  </div>
                </IonCol>
                <IonCol size='12' sizeMd='3'>
                  <div className='d-flex avatar '>
                    <div className='avatar-write'>
                      <h3 className='avatar-head'><HashLink smooth to="/how-it-works#page5">{hm('onboarding.title3')}</HashLink></h3>
                      <p className='avatar-discription' dangerouslySetInnerHTML={{__html: hm('onboarding.description3')}}></p>
                    </div>
                  </div>
                </IonCol>
                <IonCol size='12' sizeMd='3'>
                  <div className='d-flex avatar'>
                    <div className='avatar-write'>
                      <h3 className='avatar-head'><HashLink smooth to="/how-it-works#page8">{hm('onboarding.title4')}</HashLink></h3>
                      <p className='avatar-discription'>{hm('onboarding.description4')}</p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className='d-flex ion-justify-content-center'>
            <a onClick={() => history.push(`how-it-works`)}>
              <Button
                className='exploreMore'
                variant='contained'
                color='primary'
                endIcon={<ArrowRightAltIcon />}
              >
                {hm('onboarding.learn_more')}
              </Button>
            </a>
          </div>
          </div>
        </div>
        <div className='becomePartner' style={{ backgroundImage: `url(${partnerBg})` }}>
          <div className='container d-flex ion-justify-content-around arc-partner-padding'>
            <IonGrid>
              <IonRow>
                <IonCol size='12' sizeLg='6'>
                  <div className='shareguid d-flex'>
                    <div>

                      <img src={Partner} alt="Partner Icon" style={{ width: '70px', height: '77px' }} />

                    </div>
                    <div className='shareguid-content'>
                      <h4>{hm('partner.partner_title')}</h4>
                      <p>{hm('partner.partner_detail')}</p>
                      <Button className='becomePartnerButton' variant='outlined' color='primary' href="/onboarding">
                        {hm('partner.partner_button')}
                      </Button>
                    </div>
                  </div>
                </IonCol>
                <IonCol size='12' sizeLg='3'>
                  <div className='desktop-partner-logo mobile-partner-logo'>
                    <img src="https://judaica-europeana.eu/assets/img/logos/jhn-white.svg" style={{ width: '300px', height: 'auto' }} />
                  </div>
                 
                </IonCol>
                <IonCol size='12' sizeLg='3'>
                  <div className='desktop-partner-logo mobile-partner-logo'>
                    <img src="https://j-ark-cms.jhn.ngo/assets/bc4ed1c0-d84c-4a2c-858f-1e0c19d1edb7" style={{ width: '200px', height: 'auto' }} />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
              <IonCol size='12' sizeLg='7'></IonCol>
              <IonCol size='12' sizeLg='5'>
              <a className="navheader" href="mailto:info@jhn.ngo">
                        <h5><strong>info@jhn.ngo</strong></h5>
                      </a>
              </IonCol>  
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </HomePage>
      <Footer />
    </div>
  );
};

export default Home;