import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
ion-menu {
--ion-background-color: var(--ion-color-primary);
--ion-toolbar-color: var(--ion-color-primary-contrast);
--ion-item-color: var(--ion-color-primary-contrast);
}

/* --border-radius : 50px; */

*,
*::before,
*::after {
box-sizing: border-box !important;
}

.container {
max-width: 1300px;
width: 83.333%;
margin: 0 auto;
}

.main{
  // background-color: rgb(17, 79, 255);
  flex-direction: column;
  display: flex ;
  overflow-y: auto ;
  height:100vh
}

.container.wide {
// max-width: 1500px;
max-width: 1765px;
}
.hide {
overflow: hidden;
}
.cardTitle span{
// text-overflow: ellipsis;
// white-space: nowrap;
// width: 104px;
// overflow: hidden;
// cursor: pointer;
// text-align: center;
display:block;
// width:300px;
text-overflow:ellipsis;
overflow:hidden;
max-height:200px;

display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.d-flex {
display: flex;
}

.d-flex-col {
flex-direction: column;
}


.pagination .MuiPagination-ul li + li {
    margin-left: 0;
}

.strips {
background-image: repeating-linear-gradient(
-45deg,
rgba(161, 161, 161, 0),
rgba(161, 161, 161, 0.1) 5px,
rgba(161, 161, 161, 0.2) 5px,
rgba(161, 161, 161, 0.2) 7px
);
}

h2{
font-size: 3rem;
padding: 0;
margin: 0;
margin-right: auto;
font-weight: bold;
color: var(--ion-color-primary);
}
.headline h2{
color: var(--ion-color-primary-contrast)
}

.shadow {
box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
}
.map-content-wrapper{
display: flex;
width: 100%;
}
.map-content-wrapper>div:nth-child(1){
width: 71%;
order: 1;
}
// .map-content-wrapper>div:nth-child(2){
// // width: 52%;
// width: 398px;
// overflow: auto;
// height: 400px;
// margin: 0 0 50px 0;
// }
.map-content-wrapper .bqhubO {
margin-top: 0px !important;
margin-bottom: 70px;
}
.map-content-wrapper .gTifnN {
height: auto;
}
.map-content-wrapper .hdzMrx .item-preview {
height: 100%;
}
.spinner{
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}
.gvgZco .items{
gap: 1.5rem;
box-sizing: border-box;
}

// My Collection New Page Style

.title_icon{
display: flex;
/* align-items: ; */
text-decoration: none;
}

#MyCollectionList .MuiInput-underline:before{
border-bottom:none;
outline:none;
}
#MyCollectionList h6{
font-size: 1.3rem;
padding: 0;
margin: 0 0 10px 0;
margin-right: auto;
font-weight: 600;
color: var(--ion-color-primary);
display: flex;
align-items: center;
text-transform: uppercase;
}
#MyCollectionList .right-arrow{
font-size:35px;
margin-left:15px;
display:none;
}
#MyCollectionList .bottomSection{
font-size: 15px;
font-weight: 600;
color: #8a8a8a;
}
#MyCollectionList .para,
#MyCollectionList span{
font-size: 16px;
font-weight: 600;
color: #8a8a8a;
// text-overflow: ellipsis;
// white-space: nowrap;
// width: 104px;
// overflow: hidden;
}
.collectionTag{
display: flex;
justify-content: space-between;
}
.collectionTag span{
margin-bottom:0;
}

.tag-title{
margin-right:10px;
}
.tag-list span{
background-color: #0749ff;
padding: 5px 20px;
margin: 0 5px;
border-radius: 50px;
color: #fff !important;
font-size: 14px !important;
}

.collection-heading{
padding-bottom: 27px;
border-bottom: 1px solid #b3b9ca;
}

#MyCollectionList .viewMoreBtn{
position: absolute;
top: 0;
right: 0;
color: red;
z-index: 111;
background-color: rgb(14 14 14 / 60%);
width:100%;
height:100%;
border-radius: 15px;
display: none;
}
#MyCollectionList .viewMoreBtnItem{
position: absolute;
top: 0;
right: 0;
background-color: transparent;
border-radius: 15px;
transform: rotate(90deg);
}

#MyCollectionList .viewMoreBtnItem span{
color:#fff !important;
}
#MyCollectionList .media{
height: 0;
position: relative;
padding-top: 105.25%;
background-position: top center;
background-size: cover;
border-radius: 15px;
}

#MyCollectionList .media-last-card{
position: absolute;
top: 0;
right: 0;
background-color: rgb(14 14 14 / 60%);
color: #fff;
width: 100%;
height: 100%;
border-radius: 14px;
display: flex;
align-items: center;
justify-content: center;
font-size: 28px;
}

#MyCollectionList .row{
padding: 30px 0 40px;
display:flex;
flex-wrap: wrap;

}

#MyCollectionList .cards{
// max-width: 14%;
max-width: 162px;
width:200px;
margin:0 13px;
box-shadow: none;
background-color:transparent;
}

#MyCollectionList .cards:hover .viewMoreBtn{
display:block;
padding:0;
}

.gray-area{
background-color:#f1f1f1;
}

#MyCollectionList .container{
// max-width: 1510px;
// overflow: hidden;
max-width: 1765px;
margin:0 auto;
}

#MyCollectionList .container-gray{
padding: 67px 197px 0px;
}

#MyCollectionList header{
min-height: 123px;
}
#MyCollectionList .main-content-wrapper{
padding: 45px 0 15px;
}
#MyCollectionList .main-content-wrapper:hover{
background-color: #f1f1f1;
}
#MyCollectionList .main-content-wrapper:hover .right-arrow{
display:block;
width: 40%;
min-width: 35% !important;
max-width: 100% !important;
font-size: 32px !important;
background-color: #f1f1f1;
background-image: none;
text-align: left;
padding: 0 !important;
}

#MyCollectionList .pagination-section{
display: flex;
justify-content: center;
padding: 26px 0;
margin-bottom: 80px;

}

.row-content.container,
.row-content.container .col{
// height:260px;
height:100%;
}
#MyCollectionData .row-content.container,
#MyCollectionData .row-content.container .col{
height:100%;
}

#MyCollectionData .row-content.container .col{
// margin-bottom: 35px;
// width:12.5%;
// margin-right: 13px;

width: 12.5%;
// margin: 0 auto 40px;
}

#MyCollectionData .default{
width: 43px;
height: 43px;
color:#adaaaa !important;
}

#MyCollectionData .view-more-content{
width: 100%;
display: flex;
align-items: inherit;
justify-content: inherit;
transform: rotate(
90deg
);
}

#MyCollectionData .view-more-content svg{
font-size: 2.5rem !important;
}

//My Collection Data

#MyCollectionData .wide{
padding-top: 50px;
}

#MyCollectionData .view-more-content{
width: 100%;
display: flex;
align-items: inherit;
justify-content: inherit;
transform: rotate(
90deg
);
}

#MyCollectionData header{
margin-bottom: 35px;
}

#MyCollectionData .view-more-content svg{
font-size: 2.5rem !important;
}
#MyCollectionData #MyCollectionList h6 {
font-size: 1rem;
margin: 0 45px 0px 0;
}
#MyCollectionData #MyCollectionList h2 {
font-size: 2.8rem;
display:flex;
font-weight: 700;
margin-bottom: 0;
align-items: center;
}
#MyCollectionData #MyCollectionList h2 svg {
margin: 0 5px 45px;
font-size: 28px;
display: flex;
}
#MyCollectionData #MyCollectionList header h2 svg {
margin: 0 5px 0px;
font-size: 28px;
display: flex;
}

#MyCollectionData #MyCollectionList .para,
#MyCollectionData #MyCollectionList h2{
width: 46%;
}
#MyCollectionData #MyCollectionList .para,
#MyCollectionData #MyCollectionList span {
font-size: 19px;
font-weight: 500;
}

.main-Collection-tag-wrapper{
display: flex;
align-items: center;
width: 28%;
// flex-direction: column;
flex-direction: inherit;
align-items: baseline;
}
#MyCollectionData .main-Collection-tag-wrapper{
width: 28%;
flex-direction: column;
align-items: baseline;
}

#MyCollectionData .tag-list{
// width: 90%;
width:auto;
// height: 100%;
display: flex;
flex-wrap: wrap;
}
#MyCollectionData .tag-title{
margin-left: 20px;
}

#MyCollectionData .tag-list span{
margin:10px;
height: 30px;
}

#MyCollectionData .collection-heading {
padding-bottom: 106px;
border-bottom: none;
}

#MyCollectionData .heading{
display: flex;
justify-content: space-between;
}
#MyCollectionData #MyCollectionList .main-content-wrapper {
padding: 39px 0;
background: #fff;
}
#MyCollectionData header{
background: #f7f7f7;
}
.bg-gray{
background: #f7f7f7;
}
// .header-md nav{
// background: #f7f7f7;
// }

#MyCollectionData .count{
margin-right:20px
}

#MyCollectionData .count span:nth-child(1){
color: var(--ion-color-primary);
font-weight: 600 !important;
font-size: 25px !important;
margin-right:11px;
}
#MyCollectionData .count span:nth-child(2){
font-weight: 600 !important;
font-size: 16px !important;
}
#MyCollectionData #MyCollectionList .cards span {
font-size: 16px !important;
}
#MyCollectionData .displayMore{
display:flex;
flex-direction:colum;
}

#MyCollectionData #MyCollectionList .row {
padding: 58px 0 0px;
}
#MyCollectionData .displayMore{
padding-top: 43px;
display: flex;
flex-direction: column;
}
#MyCollectionData .loadMore{
width: 156px;
padding: 14px 36px;
/* font-size: 15px; */
background-color: #fac807;
border-radius: 50px;
margin-top: 30px;
box-shadow:none;
}

#MyCollectionData .loadMore span{
font-size: 14px !important;
font-weight: 800 !important;
color: #000 !important;
text-transform: capitalize;
}

.related-post{
// margin-bottom: 400px;
padding-top: 150px;
}

.related-post span{
color:#6f6b6b !important;
font-size: 21px !important;
font-weight: 600 !important;
}

.book-btn{
padding: 1px 21px;
font-size: 14px !important;
color: #000 !important;
background-color: #fac807;
border-radius: 50px;
font-weight: 900 !important;
}

#MyCollectionList .MuiSelect-select:focus {
background-color: rgb(255 255 255);
}

#MyCollectionList .select {
width: 100%;
min-width: 15ch;
max-width: 30ch;
border: 1px solid var(--select-border);
border-radius: 0.25em;
padding: 0.25em 0.5em;
font-size: 1.25rem;
cursor: pointer;
line-height: 1.1;
background-color: #fff;
background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

#MyCollectionList .bootstrap-select>select,
#MyCollectionList .bootstrap-select>.dropdown-toggle{
position:relative !Important;
}
#MyCollectionList .bootstrap-select .dropdown-menu{
max-height: 100% !important;
overflow: visible !important;
min-height: 100% !important;
height:100% !important;
transform: none !important;
top: 41px !important;
left: 0px;
will-change: unset !important;
}
#MyCollectionList .bootstrap-select:not(.input-group-btn),
#MyCollectionList .bootstrap-select[class*=col-] {
display: flex !important;
flex-direction: column !important;
}
#MyCollectionList .order-collection-title{
margin-right: 18rem;
}

.tablet-view{
display:none;
}

.MuiPagination-ul button.Mui-selected{
color: #0749ff;
background-color: transparent;
}

// .sc-ion-popover-md-h{
// --width:140px !important;
// }

// Custom Select
.select-box {
position: relative;
display: block;
// width: 30%;
width: 20%;
font-size: 18px;
color: #60666d;
}

#MyCollectionData .select-box{
width: 33%;
}

.select-box__current {
position: relative;
cursor: pointer;
outline: none;
}
.select-box__current:focus + .select-box__list {
opacity: 1;
animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
cursor: pointer;
}
.select-box__current:focus .select-box__icon {
transform: translateY(-50%) rotate(180deg);
}
.select-box__icon {
position: absolute;
top: 50%;
right: -3px;
transform: translateY(-50%);
width: 11px;
opacity: 0.3;
transition: 0.2s ease;
}
.select-box__value {
display: flex;
}
.select-box__input {
display: none;
}
.select-box__input:checked + .select-box__input-text {
display: block;
}
.select-box__input-text {
display: none;
width: 100%;
margin: 0;
padding: 15px;
color: #7a84a2;
font-weight: bold;
font-size: 15px;
background-color: #fff;
}
.select-box__list {
position: absolute;
width: 100%;
padding: 0;
list-style: none;
opacity: 0;
animation-name: HideList;
animation-duration: 0.5s;
animation-delay: 0.5s;
animation-fill-mode: forwards;
animation-timing-function: step-start;
box-shadow: 0 0px 30px -10px rgb(10 10 10 / 33%);
z-index:99999;
}
.select-box__option {
display: block;
padding: 5px 15px;
background-color: #fff;
font-size: 14px;
}
.select-box__list li:first-child .select-box__option{
border-top: 1px solid #cccccc61;
border-left: 1px solid #cccccc61;
border-bottom: 1px solid transparent;
border-right: 1px solid #cccccc61;
border-top-right-radius: 10px;
border-top-left-radius: 10px;
}
.select-box__list li .select-box__option{
border-top: 1px solid transparent;
border-left: 1px solid #cccccc61;
border-bottom: 1px solid transparent;
border-right: 1px solid #cccccc61;
padding-left:20px;
}
.select-box__list li:last-child .select-box__option{
border-top: 1px solid transparent;
border-left: 1px solid #cccccc61;
border-bottom: 1px solid #cccccc61;
border-right: 1px solid #cccccc61;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
}

.select-box__option:hover, .select-box__option:focus {
color: #546c84;
background-color: #fbfbfb;
}
@keyframes HideList {
from {
transform: scaleY(1);
}
to {
transform: scaleY(0);
}
}
.custom-select-wrapper{
display:flex;
align-items:center;
width:50%;
justify-Content:flex-end;
}
#MyCollectionData .custom-select-wrapper{
width:30%;
}

.MuiPaginationItem-textPrimary.Mui-selected:hover, .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
background-color: #0749ff !important;
color: #fff;
}

.upload-btn{
padding:0 !important;
width:100%;

}
.upload-btn .upload-file-wrapper {
width: 100%;
margin: 0 auto;
min-width: 100%;
height: 11rem;
min-height: 100%;
}
.upload-btn .upload-file-wrapper .text {
font-size: 11px;
}

.recommended-cards-wrapper .recommendedCard{
// width: 25.65rem;
width: 25.7vw;
// height:100%;
margin-right: 3px;
}
.recommended-cards-wrapper .recommendedCard .search{
width: 10px;
}

.recommended-cards-wrapper .recommendedCard .item-preview {
width: 7rem;
}

.recommended-cards-wrapper .recommendedCard ion-button {
font-size: 10px !important;
font-weight: 800 !important;
width: 100%;
margin: 6px 0 0 -6px;
padding: 0px 0;
height:25px;
}

.recommended-cards-wrapper .recommendedCard .read-more-link {
width: 100% ;
font-size: 10px;
}

.button-native{
padding: 13px;
}


ion-button.abc .button-inner {
  display: block !important;
  position: relative;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 72px !important;
  height: 13px !important;
  z-index: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.item-details-description {
  color: var(--ion-color-secondary);
  font-size: 1rem;
  line-height: 1.25;
  display: -webkit-box;
  height: 56px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.count-title{
display:flex;
align-items:center;
}

.count-list{
display:flex;
align-items:center;
}

.ion-page{
background-color: #f7f7f7;
}

#postDetails header{
  display: flex;
  align-items: flex-start;
  background-color: #f7f7f7;
}

.card-description-wrap{
width: 40%;
padding: 160px 120px;
}

.post-content-wrap {
background-color: transparent;
// margin: -100px 0;
margin: -9px 0;
// padding: 100px 0;
padding: 0px 0;
}

.detailone{
width:100%;
}
#postDetails{
margin-top: -92px;
}
#postDetails .description {
width: 100%;
margin-right:0 ;
}

#postDetails .preview {
width: 69.25rem;
height: 55.9rem;
margin-right: 0;
position: relative;
}

#postDetails .preview span{
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
}
#postDetails .preview span svg{
  font-size: 55px;
}



#postDetails .preview img{
height: 100%;
object-fit: contain;
// border: 1px solid #795548;
// border-radius: 30px;
}

#postDetails .custom-select-wrapper{
align-items: center;
justify-content: flex-start;
width: 100%;
}
#postDetails .book-wrapper {
display:flex;
}

#postDetails .book-wrapper .book span{
background-color: #fac807;
color: #000;
font-size: 16px;
font-weight: 700;
border-radius: 50px;
padding: 5px 30px;
margin-right: 20px;
}
#postDetails .book-wrapper .book {
margin-right: 30px;
}
#postDetails .book-wrapper{
margin-bottom: 37px;
}

#postDetails .book-wrapper .logo-img{
width:70%;
}
#postDetails .container{
// max-width: 1500px;
max-width: 1765px;
}

#postDetails .select-box {
position: relative;
display: block;
width: 40% !important;
font-size: 18px;
font-weight:700;
color: #0749ff !important;
}

#postDetails .address-nav {
display: flex;
}
#postDetails .address-nav svg{
font-size: 32px;
color: #0749ff;
margin-right: 10px;
}
#postDetails .title_icon{
margin: 0 10px;
color: #8b94ae;
font-size: 16px;
text-decoration-line: underline;
}
#postDetails .para,
#postDetails span{
font-weight: 600;
color: #8b94ae;
}
.backarrow{
color:#8b94ae
}
#postDetails .para{
font-size: 21px;
font-weight: 400;
// min-height: 110px;
// height: 110px;
min-height: 100%;
height: 100%;
    overflow: auto;
}
#postDetails .original-text-wrap{
display:flex;
flex-direction:column;
justify-content: center;
}

#postDetails .original-text-wrap img{
width: 100%;
}

#postDetails .original-text-wrap,
#postDetails .provider {
width:20%;
}

#postDetails .provider{
border-right: 1px solid;
margin-right: 15px;
}

#postDetails .original-text-wrap{
width: 12%;
margin-right: 30px;
}

#postDetails .original-text-wrap span:nth-child(2){
font-weight: 600;
color: #0749ff;
}
#postDetails .heading-text-wrap{
margin-top:15px;
}
#postDetails .original-text-wrap span:nth-child(1),
#postDetails .provider span:nth-child(1){
margin-bottom: 15px;
}
#postDetails .title {
margin-bottom: 37px;
  white-space: nowrap;
  width: 100%;
  font-size: 1.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 225px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

#postDetails .provider span:nth-child(2),
#postDetails .provider .title_icon{
color: #0749ff;
font-weight: 600;
width: 120px;
}

.relative-cards{
width: 21rem;
height: 12.75rem;
font-size: 1.375rem;
line-height: 1.2;
}
.relative-cards .item-preview {
width: 5.5rem;
height: 100%;
object-fit: contain;
border-radius: 16px;
}
.relative-cards .shadow{
gap: 0.5rem;
}

#postDetails .relative-cards ion-icon {
cursor: pointer;
font-size: 11px !important;
}

#postDetails ion-icon {
cursor: pointer;
font-size: 15px !important;
margin-bottom: 0px;
}

#postDetails .backarrow {
  cursor: pointer;
font-size: 15px !important;
margin-bottom: 80px;
}



#postDetails .custom-select-wrapper.main-custom-select-wrap {
width: 69%;
}

.bottom-content-wrap{
  margin-top: 1.5rem;
}

.bottom-content-wrap .actions{
margin-top:0px;
}

// Custom Select
.select-box {
position: relative;
display: block;
// width: 100%;
width: 30%;
font-size: 18px;
color: #60666d;
}

.select-box__current {
position: relative;
cursor: pointer;
outline: none;
border-radius:10px;
}
.select-box__current:focus + .select-box__list {
opacity: 1;
animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
cursor: pointer;
}
.select-box__current:focus .select-box__icon {
transform: translateY(-50%) rotate(180deg);
}
.select-box__icon {
position: absolute;
top: 50%;
right: 15px;
transform: translateY(-50%);
width: 8px;
opacity: 0.3;
transition: 0.2s ease;
}
.select-box__value {
display: flex;
}
.select-box__input {
display: none;
}

.select_block .select-box__input-text {
color: #1e4eff;
}
.select-box__input:checked + .select-box__input-text {
display: block !important;
}
.select-box__input-text {
display: none;
width: 100%;
margin: 0;
padding: 15px;
background-color: transparent;
font-size:14px;
}
.select-box__list {
position: absolute;
top: 30px;
width: 100%;
padding: 0;
list-style: none;
opacity: 0;
animation-name: HideList;
animation-duration: 0.5s;
animation-delay: 0.5s;
animation-fill-mode: forwards;
animation-timing-function: step-start;
box-shadow: 0 0px 30px -10px rgb(10 10 10 / 33%);
}
.select-box__option {
display: block;
padding: 5px 15px;
background-color: #fff;
font-size: 14px;
}
.select-box__list li:first-child .select-box__option{
border-top: 1px solid #cccccc61;
border-left: 1px solid #cccccc61;
border-bottom: 1px solid transparent;
border-right: 1px solid #cccccc61;
border-top-right-radius: 10px;
border-top-left-radius: 10px;
}
.select-box__list li .select-box__option{
border-top: 1px solid transparent;
border-left: 1px solid #cccccc61;
border-bottom: 1px solid transparent;
border-right: 1px solid #cccccc61;
padding-left:20px;
}
.select-box__list li:last-child .select-box__option{
border-top: 1px solid transparent;
border-left: 1px solid #cccccc61;
border-bottom: 1px solid #cccccc61;
border-right: 1px solid #cccccc61;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
}

.select-box__option:hover, .select-box__option:focus {
color: #546c84;
background-color: #fbfbfb;
}
@keyframes HideList {
from {
transform: scaleY(1);
}
to {
transform: scaleY(0);
}
}
.custom-select-wrapper{
display:flex;
align-items:center;
width:50%;
justify-Content:flex-end;
}
.MuiPaginationItem-textPrimary.Mui-selected:hover, .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
background-color: #0749ff !important;
color: #fff;
}


//******* New Search *******************

 .newSearchFilter ion-button {
  min-width: 102px;
  margin: 7px 5px;
  font-size: 12px !important;
 }

//  .newSearchFilter ion-button .button-inner {
//    display: block !important;
//     position: relative;
//     flex-flow: row nowrap;
//     flex-shrink: 0;
//     align-items: center;
//     justify-content: center;
//     width: 72px !important;
//     height: 13px !important;
//     z-index: 1;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;
//  }

 .new-search .item-details-description {
  font-size: 0.9rem;
 }
 .new-search .recommended-cards-wrapper .recommendedCard{
  height:100%;
 }
 .new-search .recommended-cards-wrapper .recommendedCard .item-preview {
  height: auto;
 }
 .new-search .info+div{
   margin-bottom: 8rem;
 }

 .new-search .filter-items{
   width: 30%;
 }
 .new-search .filter-items>div{
  width: 100%;
}
.new-search .map-content-wrapper>div:nth-child(2) {
  // width: 52%;
  width: 398px;
  overflow: auto;
  height: 400px;
  margin: 0 0 140px 0;
}



//**************************************
// Media Query
@media screen and (max-width: 1900px){
  .recommended-cards-wrapper .recommendedCard {
    width: 18.9vw;
  }
}
@media screen and (max-width: 1581px){
.recommended-cards-wrapper .recommendedCard {
  width: 18.85vw;
}
.recommended-cards-wrapper .recommendedCard .item-preview {
  width: 5rem;
}
}
@media screen and (max-width: 1502px){
  #MyCollectionData #MyCollectionList h6 {
    margin: 0 10px 0px 0
  }
  #MyCollectionData .count {
    margin-right: 11px;
}
}
@media screen and (max-width: 1440px) {
    upload-btn .MuiButton-label {
    width: 94%;
    }
    .container {
    max-width: 1300px;
    width: 90.333%;
    margin: 0 auto;
    }
    #MyCollectionList .para, #MyCollectionList span {
    font-size: 1.11vw;
    }

    #MyCollectionList .cards {
      max-width: 126px;
      width: 200px;
    }
    .upload-btn .upload-file-wrapper {
      min-height: 100%;
      height: 100%;
      padding: 7px 0;
      width: 100%;
      margin: 0 auto;
      min-width: 100%;
    }
    #MyCollectionList .row-content.container .col-auto {
      margin: 25px 0;
    }
    #postDetails .custom-select-wrapper {
      width: 869px !important;
    }
    .recommended-cards-wrapper .recommendedCard {
      width: 20.3vw;
  }
  .item-details-description {
-webkit-line-clamp: 3;
  }
  
}

@media screen and (max-width: 1336px) {
.recommended-cards-wrapper .recommendedCard {
  width: 27.3vw;
}
/* .item-details-description {
-webkit-line-clamp: 3;
  } */
}


@media screen and (max-width: 1200px) {
  .count-wrapper {
    flex-direction: column;
  }
  #MyCollectionData .custom-select-wrapper{
    align-items: flex-end;
  }
  .select-box__input-text{
    padding:0;
  }

  .upload-btn .upload-file-wrapper {
    width: 13rem;
    min-height: 13rem;
  }
  #MyCollectionData .row-content.container .col {
    width: 22.5%;
    margin: 0 auto 10px;
  }
}
@media screen and (max-width: 1199px) {
  .row-content.container.container{
    max-width: 100%;
      width: 100%;
      padding: 0 7%;
      margin: 0 auto;
  }
  .collectionTag>span{
    width:18%;
  }
  #MyCollectionList .collectionTag>span{
    width:100%;
  }
  .collectionTag{
   display:flex;
   align-items:flex-end;
  }
  #MyCollectionData .collectionTag{
    display:flex;
    align-items: baseline;
   }

  .collectionTag>span+div{
    display:flex;
    align-items: baseline !important;
    flex-direction: column;
  }
  #MyCollectionList .collectionTag .tag-title{
    margin-bottom: 38px;
    margin-top: -73px;
    font-size: 1.2rem;
    color: #0749ff;
  }
  .tag-list span {
    margin: 0 1px;
  }
  .main-Collection-tag-wrapper {
    width: 45%;
  }
  .collectionTag>span+div {
    flex-direction: inherit;
  }
  .upload-btn{
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
  }
  .upload-btn .upload-file-wrapper{
    width: 100%;
    min-width: 100%;
    height: 11em;
    min-height: 100%;
    padding: 0px 0;
  }
}
@media screen and (max-width: 1024px) {
 
  #MyCollectionList .tag-list span {
    font-size: 1.11vw !important;
    display: flex;
    align-items: center;
  }
  #MyCollectionList .collectionTag .tag-title {
    margin-bottom: 25px;
  }
  #MyCollectionData #MyCollectionList .para, #MyCollectionData #MyCollectionList h2 {
    width: 67%;
  }
  .upload-btn .upload-file-wrapper {
    width: 100%;
    min-width: 100%;
    height: 9em;
    min-height: 100%;
    padding: 0px 0;
  }
  .select-box__list {
    width: 150px;
    right: 0;
  }
  #MyCollectionList .collectionTag{
    display: flex;
    flex-direction:column;
  }
  #MyCollectionList .MuiTypography-caption.span{
    width:100%;
  }
  #MyCollectionList .tag-list{
    display:flex;
  }
  .new-search .map-content-wrapper>div:nth-child(1) {
    margin-left: 20px;
  }
}
@media screen and (max-width: 979px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 42.5vw;
  }
  }
@media screen and (max-width: 991px){
  .count-list {
    flex-wrap: wrap;
  }
  #postDetails .custom-select-wrapper {
    width: 100% !important;
}
#postDetails .preview img {
  height: 78%;
}
#postDetails .preview {
  margin: -150px auto 0;
}
}

.description-para-wrap{
  margin: 44px 0px;
}

@media screen and (max-width: 767px) {
  #MyCollectionData .custom-select-wrapper {
  width: 75%;
  }
  #MyCollectionList .collectionTag .tag-title {
  margin-top: -9%;
  }
  #MyCollectionList .para, #MyCollectionList span {
  font-size: 0.9rem !important;
  }
  .tablet-view{
  display:block;
  }
  .desktop-view{
  display:none;
  }
  .collectionTag{
  flex-direction: column;
  }
  #MyCollectionList .collectionTag>div{
  display: flex;
  align-items: baseline !important;
  flex-direction: column !important;
  width: 100% !important;
  
  }
  #MyCollectionData #MyCollectionList .collectionTag>div{
  margin-top: 71px;
  margin-bottom: -50px;
  }
  #MyCollectionList .collectionTag .tag-title {
  margin-top: -5%;
  margin-bottom: 10px;
  }
  
  #MyCollectionList .tag-list span {
  font-size: 0.7rem!important;
  display: flex;
  align-items: center;
  }
  
  .tag-list{
  display: flex;
  flex-wrap: wrap;
  }
  .tag-list span{
  margin: 6px 6px;
  }
  #MyCollectionList header h2 {
  font-size: 1.5rem;
  }
  .cardTitle span {
  width: 72px;
  }
  .select-box__icon {
  right: 0%;
  }
  .select-box {
  width: 40%;
  }
  #MyCollectionData #MyCollectionList .para, #MyCollectionData #MyCollectionList h2 {
  width: 100%;
  font-size: 5vw;
  margin-bottom: 16px;
  }
  .upload-btn .upload-file-wrapper {
  height: 100%;
  min-height: 94%;
  padding: 0px 0;
  }
  #MyCollectionList .collectionTag{
  margin-top: 55px;
  }
  .recommended-cards-wrapper .recommendedCard {
    width: 100vw;
}
.recommended-cards-wrapper .recommendedCard .item-preview {
  width: 8rem;
}
  }

@media only screen and (max-width: 1980px) {
#postDetails .custom-select-wrapper {
// width: 869px !important;
justify-content: flex-start;
}
#postDetails .original-text-wrap, #postDetails .provider {
width: 41%;
}
.card-description-wrap {
padding:60px 3%;
}
.post-content-wrap {
  margin: -138px 0;
}
.wrap{
  margin-bottom: 200px;
}
#postDetails .original-text-wrap img {
  width: 75%;
}
// #postDetails .select-box {
//   width: 28% !important;
// }
}

@media only screen and (max-width: 1199px) {
#postDetails .para, #postDetails span {
font-size: 15px;
}
#postDetails .title_icon {
  font-size: 15px;
}
#postDetails .select-box {
  width: 15% !important;
}
}

@media only screen and (max-width: 1440px){
  #postDetails {
    margin-top: 100px;
}
#postDetails .wide{
  margin-top: -100px;
}
}

@media only screen and (max-width: 1024px){
.card-description-wrap {
  padding: 30px 3%;
}
#postDetails .book-wrapper+h4.title {
  width: 313px !important;
  text-overflow: clip !important;
  white-space: pre-line;
  font-size: 29px;
}
#postDetails .custom-select-wrapper {
  width: 100% !important;
}
#postDetails .select-box {
  width: 50% !important;
}
}


@media only screen and (max-width: 991px){
#postDetails .custom-select-wrapper {
width: 100% !important;
}
#postDetails .preview img {
height: 78%;
}
#postDetails .preview {
margin: -150px auto 0;
}
.description-para-wrap {
  margin: 25px 0px;
}
.post-content-wrap {
  padding: 94px 0;
}
#postDetails ion-icon {
  margin-bottom: 5px;
}
}

@media only screen and (max-width: 767px){
#postDetails .custom-select-wrapper {
justify-content: end;
}
#postDetails .select-box {
width: 17% !important;
}
#postDetails .preview img {
height: 100%;
}
.post-content-wrap .container>div{
flex-direction: column;
}
#postDetails .preview {
  width: 100%;
  height: 100%;
  margin-right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.details {
width: 100%;
margin-top: 50px;
}
.post-content-wrap {
margin: 0;
padding: 0;
}
.custom-select-wrapper>span{
font-size: 3.3vw;
}
#postDetails header {
  flex-direction: column;
}
.card-description-wrap {
  width: 100%;
}
.card-description-wrap {
  padding: 30px 8%;
}
.wrap {
  display: flex;
  align-items: center;
}

.wrap .backarrow{
  margin-right:50px;
}
#postDetails .book-wrapper+h4.title {
  width: 100% !important;
}

#postDetails .original-text-wrap, #postDetails .provider {
  width: 17%;
}
@media screen and (max-width: 575px) {
  #MyCollectionList header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
  .custom-select-wrapper{
    display:flex;
    align-items:center;
    width:100%;
    justify-Content:end;
  }
  #MyCollectionData .heading {
    flex-direction: column;
  }
  .count-list {
    margin: 15px 0;
  }
  .upload-btn .upload-file-wrapper {
    height: 107px;
    min-height: 50px;
    padding: 0px 0;
  }
}
@media only screen and (max-width: 425px){
  #postDetails .select-box {
      width: 34% !important;
  }
  .newSearchFilter .filter-wrap{
    display: flex;
    flex-wrap: wrap;
  }
  .newSearchFilter .filter-items{
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    margin-left:0 !important;
  }
  .newSearchFilter>div .filter-items>div{
    width: 100%;
    height:100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .newSearchFilter>div>div:nth-child(3){
    margin-top: 30px;
  }

  .newSearchFilter .clear {
    margin-left: 0;
    margin-top: 30px;
  }

  .new-search header{
    display:flex;
    flex-direction:column;
  }
  .new-search header h2{
    margin:10px auto;
  }
  .new-search header h2+div{
   width:100%;
   margin:10px auto;
  }
  .list-shwo-hide{
    display: flex;
    flex-direction: column;
  }
  .list-shwo-hide>div:nth-child(2){
    float: none;
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }
  .new-search .recommended-cards-wrapper .recommendedCard {
    margin: 0 auto;
  }
  .map-content-wrapper{
    flex-direction: column;
  }
  .map-content-wrapper>div:nth-child(1) {
    width: 90%;
    margin:0 auto;
    order: 0;
  }
  .new-search .map-content-wrapper>div:nth-child(2) {
    margin: 30px 0 140px 0;
  }
}
@media screen and (max-width: 360px) {
  .upload-btn .upload-file-wrapper .text {
    font-size: 2.5vw;
  }
  .upload-btn .upload-file-wrapper {
    height: 79px;
  }

  #postDetails .select-box {
    width: 34% !important;
  }
}


}`;

export default GlobalStyle;
