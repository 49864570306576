import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';
import ArchiveLogo from '../../assets/logo/archive-logo.png';
import { ArchiveDemostyle } from './ArchiveDetailsstyle';
import {cmsImage2Url} from '../../util/API';
import {translateArchive} from '../../util/useTranslation';

type Props = { data: Array<any> }

const ArchiveDetails: React.FC<Props> = (props: Props) => {
    console.log("DATA: " + props.data);
	return (
		<div>
			<ArchiveDemostyle>
				<div className='margin-bottom-19'>
					<IonGrid className='padding-sides-0' >
						<IonRow>
							{props.data.map((data: any, i: any) => {
								let tr_data = translateArchive(data);
								return (
									<IonCol size='12' sizeXl='6' key={i} className='padding-sides-0'>

										<IonRow className='item-row ion-padding'>
											{ tr_data.images?.length > 0 ?
											<IonCol size='12' sizeLg='4' className='d-flex '>
												<img src={cmsImage2Url(tr_data.images[0]?.directus_files_id)} alt="Archive Image" className='item-img-start' />
											</IonCol>
											: "" }
											<IonCol size='12' sizeLg='4' className='middle-content'>
												<div className='partner-project-name d-flex ion-justify-content-start'>
													<span>{data?.provider?.name}</span>
												</div>
												<div>
													<h4 className='jewish-archive d-flex ion-justify-content-start'>
														<Link to={'/archive/' + data.slug} className='item-title-link'>
															{tr_data.title}
														</Link>
													</h4>
												</div>
												<div className='d-flex ion-justify-content-start'>
													<p className='item-details-description'>
														{tr_data.short_description}
													</p>
												</div>
											</IonCol>
											<IonCol size='12' sizeLg='4' className='d-flex border-left'>
												<img src={cmsImage2Url(data.provider?.logo)} alt="Archive Logo" className='item-img-end' />
											</IonCol>
										</IonRow>
									</IonCol>
								);
							})}
						</IonRow>
					</IonGrid>
				</div>
			</ArchiveDemostyle >
		</div >
	);
}

export default ArchiveDetails;