import { IonCol, IonGrid, IonRow } from '@ionic/react';

import OpenLink from '../../assets/avatar/open-link.svg';
import Reliable from '../../assets/avatar/reliable.svg';
import Standard from '../../assets/avatar/standard.svg';
import React, { useState, useRef } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import {useTranslations} from '../../util/useTranslation';
import { HomePage as Homestyle } from '../Home/Homestyle';
import TableOfContents from './TableOfContents';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EU_LANGUAGES = [
{ code: 'bg', name: 'Bulgarian' },
{ code: 'hr', name: 'Croatian' },
{ code: 'cs', name: 'Czech' },
{ code: 'da', name: 'Danish' },
{ code: 'nl', name: 'Dutch' },
{ code: 'en', name: 'English' },
{ code: 'et', name: 'Estonian' },
{ code: 'fi', name: 'Finnish' },
{ code: 'fr', name: 'French' },
{ code: 'de', name: 'German' },
{ code: 'el', name: 'Greek' },
{ code: 'hu', name: 'Hungarian' },
{ code: 'ga', name: 'Irish' },
{ code: 'it', name: 'Italian' },
{ code: 'lv', name: 'Latvian' },
{ code: 'lt', name: 'Lithuanian' },
{ code: 'mt', name: 'Maltese' },
{ code: 'pl', name: 'Polish' },
{ code: 'pt', name: 'Portuguese' },
{ code: 'ro', name: 'Romanian' },
{ code: 'sk', name: 'Slovak' },
{ code: 'sl', name: 'Slovenian' },
{ code: 'es', name: 'Spanish' },
{ code: 'sv', name: 'Swedish' }

  ];

const HowItWorks: React.FC = () => {
	const [co] = useTranslations('how_it_works');

	const [numPages, setNumPages] = useState(null);
	const [lang, setLang] = useState("en");
	const pageRefs = useRef({});

	
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
	function onItemClick({ pageNumber }) {
		console.log("Page changed to " + pageNumber)
		pageRefs.current[pageNumber].scrollIntoView({ behavior: 'smooth' });

	  }


	return (
		
		<div className='main' style={{ overflowY: 'scroll', height: '100vh' }}>
			<Header />
			<Homestyle>


				<div className='container'>
					<span className='title'>{co('title')}</span>
				{/* 	<object data="https://j-ark.org/tutorial_en.pdf?#view=FitVH" type="application/pdf" style={{height: "1500px", width: '100%', margin: 'auto'}}>
						<div>No PDF viewer available</div>
					</object>
				*/}							
					<div id="tutorial">
					<select value={lang} onChange={(e) => setLang(e.target.value)} style={{zIndex: 1000, marginBottom: "10px", marginTop: "-10px", color: "#717C9C"}}>
						{EU_LANGUAGES.map(language => (
							<option key={language.code} value={language.code}>
							{language.name}
							</option>
						))}
					</select>
						<Document className={"tutorial-document"} file={`./assets/tutorial/${lang}.pdf`} 
									onLoadSuccess={onDocumentLoadSuccess}
									onItemClick={onItemClick}>
							{Array.from(new Array(numPages), (el, index) => (
								<div id={`page${index}`} style={{textAlign: "center"}}>
									<Page className={"tutorial-page"} inputRef={el => { pageRefs.current[index] = el; }} height={1500} key={`page_${index + 1}`} pageNumber={index + 1} />
								</div>
							))}						
						</Document>
					</div>
				</div>
			</Homestyle>
			<Footer />
		</div>
	);
}

export default HowItWorks;