import styled from "styled-components";

export const Archivesstyle = styled.div`
--body{
    overflow:hidden;
}

.sc-ion-searchbar-md-h {
    --color: #717C9C;
    --icon-color: #717C9C;

}
.about-this-wrap{
    padding: 109px 0 35px;
    border-bottom: 1px solid #7782A0;
}
.about-this-content-wrap{
    width: 100%;
    margin-bottom: 87px;
    font-family: "Source Sans Pro";
    font-weight: bold;
}
.about-this-content-wrap h2{
    height: 137px;
    width: 710px;
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 87px;
}
.searchbarTool {
    border-radius: 50px;
    width: 459px;
    height: 40px;
}
.searchbarInput {
    --box-shadow: none !important;
}
[placeholder]{
    text-overflow:ellipsis;
}
ion-icon.icon-right {
    cursor: pointer;
    width: 35px;
    height: 20px;
    margin-right: 13px;
}
.pagination-wrap {
    margin-top: 116px;
}
.experiences{
    color: #717C9C;
    padding-bottom: 35px;
}
.pagination{
    display:flex;
    justify-content:center;
    margin-bottom:118px
}
.pagination li button{
    color: 717C9C;
}
.pagination li + li {
    margin-left: 0;
}

@media only screen and (max-width: 991px) {
    .about-this-wrap{
        padding: 40px 0 35px;
    }
    .about-this-content-wrap{
        margin-bottom: 87px;
    }
    .about-this-content-wrap h2{
        width: 400px;
    }
}

@media only screen and (max-width: 575px) {
    .about-this-content-wrap{
        margin-bottom: 40px;
    }
    .about-this-content-wrap h2{
        max-height: 180px;
        max-width: 305px;
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 0px;
    }
    .searchbarTool {
        width: 305px;
    }
    .pagination-wrap {
        margin-top: 86px;
    }
    .experiences{
        padding-bottom: 23px;
    }
    .pagination{
        margin-bottom:65px
    }
}
`;