import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const DEFAULT_LANG = 'en';

const SUPPORTED_LANGS = ['en', 'de', 'fr', 'es', 'it']

const useTranslations = (...i18ns: string[]) => {
  const location = useLocation();
  const { t, i18n } = useTranslation<string[]>(i18ns);
  const [lang, setLang] = useState(DEFAULT_LANG);
  let selectedLanguage = localStorage.getItem('language');

  useEffect(() => {
    const langParam =
      new URLSearchParams(location.search)?.get('lang') ?? DEFAULT_LANG;
    setLang(langParam);
    console.log(i18n.options);
    if (selectedLanguage != null) {
      localStorage.setItem('language', selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    }
    console.log("Changed language to " + langParam);
  }, [i18n, location.search]);

  return [t, lang, setLang] as const;
};

const currentLanguage = () => {
  let selectedLanguage = localStorage.getItem('language');
  return selectedLanguage == null ? DEFAULT_LANG : selectedLanguage;
}

const translateArchive = (archive) => {
  let lang = currentLanguage();
  let translations = archive?.translations?.filter((t) => {
    return t.languages_id == lang
  })
  if (translations?.length > 0) {
    return {
      ...archive,
      ...translations[0]
    }  
  }
  return archive;
}

export {useTranslations, SUPPORTED_LANGS, DEFAULT_LANG, currentLanguage, translateArchive};