import axios, { AxiosResponse } from 'axios';


const CMS_URL = process.env.REACT_APP_CMS_URL;
const CMS_TOKEN = process.env.REACT_APP_CMS_TOKEN;

const ARCHIVES_PATH = "/items/archives";
const PARTNERS_PATH = "/items/partners";
const IMAGES_PATH="/assets"

function token2params() {
    return `access_token=${CMS_TOKEN}`
}

function cmsImage2Url(cms_image_id) {
    return `${CMS_URL}${IMAGES_PATH}/${cms_image_id}`;

}

  
  const http = axios.create({
    baseURL: CMS_URL,
    responseType: 'json',
  });

const getArchives = async () => {
    return await http.get(`${ARCHIVES_PATH}?filter[status][_eq]=published&fields=*.*&${token2params()}`);
}

const getSingleArchive = async (slug: any) => {
    return await http.get(`${ARCHIVES_PATH}/?filter[slug][_eq]=${slug}&$&fields=*.*&${token2params()}`);
}


const getPartners = async () => {
    return await http.get(`${PARTNERS_PATH}?fields=*.*&${token2params()}`);
} 
export {getPartners, getArchives, getSingleArchive, cmsImage2Url}