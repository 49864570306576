import { IonIcon, IonSearchbar, IonToolbar } from '@ionic/react';
import Pagination from '@material-ui/lab/Pagination';
import { arrowForward } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import ArchiveDetails from '../../components/ArchiveDetails/ArchiveDetails';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import { getArchives } from '../../util/API';
import {useTranslations} from '../../util/useTranslation';
import { Archivesstyle } from './Archivesstyle';

const Archives: React.FC = () => {
	const [co] = useTranslations('common');
	const [searchText, setSearchText] = useState('');
	const [archiveData, setArchiveData] = useState<any>([]);
	const [page, setPage] = useState<number>(1);
	const itemsPerPage = 8;
	const [allData, setAllData] = useState<any>([]);

	const handleChange = event => {
		setSearchText(event.target.value);
	}

	const onIconClick = () => {
		if (searchText) {
			const results = archiveData.filter(detail =>
				detail.title.toLowerCase().includes(searchText.toLocaleLowerCase()) ||
				detail.description.toLowerCase().includes(searchText.toLocaleLowerCase())
			);
			setAllData(results.slice((page - 1) * itemsPerPage, page * itemsPerPage));
			setPage(1);
		}
		else {
			setAllData(archiveData.slice((page - 1) * itemsPerPage, page * itemsPerPage))
			setPage(1);
		}
	};

	const onPageChange = (event, value) => {
		setPage(value);
	};

	useEffect(() => {
		setAllData(archiveData.slice((page - 1) * itemsPerPage, page * itemsPerPage))
	}, [archiveData, page]);

	useEffect(() => {
		const fetchArchiveData = async () => {
			const results: any = await getArchives();
			if (results.data.data.length) {
				setArchiveData(results.data.data);
				setAllData(results.data.data);
			}
		};
		fetchArchiveData();
	}, []);

	return (
		<div className='main' style={{ overflowY: 'scroll', height: '100vh' }}>
			<Header />
			<Archivesstyle>
				<div className='archive-wrap'>
					<div className='container wide'>
						<div style={{ marginBottom: '35px' }}>
							<div className='about-this-wrap'>
								<div className='about-this-content-wrap'>
									<div>
										<h2>{co("archives.header")}</h2>
									</div>
								</div>
								<IonToolbar className='searchbarTool d-flex ion-align-items-center' >
									<IonSearchbar
										showCancelButton="never"
										showClearButton="never"
										autocomplete="on"
										value={searchText}
										className='searchbarInput'
										placeholder="Type in archive or project name"
										onIonChange={handleChange}
									>
									</IonSearchbar>
									<IonIcon
										className='icon-right'
										icon={arrowForward}
										slot='end'
										color='primary'
										onClick={onIconClick}
									/>
								</IonToolbar>
							</div>
						</div>
						<ArchiveDetails data={allData} />
						<div className='pagination-wrap'>
							{allData.length ? (
								<div className='d-flex ion-justify-content-center'>
									<span className='experiences'>
										Displaying {Math.min(itemsPerPage, archiveData.length)} out of {archiveData.length} archives
									</span>
								</div>
							) : null}
							<div className='pagination-section container'>
								<div className='pagination'>
									{allData.length ? (
										<Pagination
											hidePrevButton={page == 1 ? true : false}
											hideNextButton={
												page == Math.ceil(archiveData.length / itemsPerPage)
													? true
													: false
											}
											onChange={onPageChange}
											count={Math.ceil(archiveData.length / itemsPerPage)}
											page={page}
											defaultPage={1}
											color='primary'
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Archivesstyle>
			<Footer />
		</div>
	);
}

export default Archives;