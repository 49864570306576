import styled from 'styled-components';

export const HomePage = styled.div`
--body{
    overflow:hidden;
}

.navheader {
    color: #FFFFFF;
}

.header-container {
    width: 100%;
}

.home-header-container {
    margin-top: 9vh;
    margin-bottom: 5vh;

}

.container {
    max-width: 1765px;
    margin: 0 auto;
}
.banner-content-wrap{
    width: 70%;
}
h4,p {
    padding: 0;
    margin: 0;
}
.logocontainer {
    margin-bottom: 64px;
}
.preserve-and-provide {
    width: 649px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 64px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 64px;
}
.banner-inner-img{
    width:  591px;
    height: 409px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.partition {
    background-color: #f7f7f7;
    width: 12%;
}
.discription{
    margin-top: 95px;
    margin-bottom: 101px;
    display: flex;
    flex-direction: column;
}
.row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.discription-left {
    flex: 50%;
}
.left{
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    padding-right: 30px;
    text-align: left;
}
.discription-right {
    flex: 50%;
}
.right{
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 30px;
    padding-left: 25px;
    text-align: left;
}
.padding-bottom-110-and-21 {
    padding-bottom: 110px;
}
.margin-right-60 {
    margin-right: 60px;
}
.avatar {
    flex-direction: row;
}
.avatar-img {
    width: 89px;
    height: 89px;
    margin: 16px 32px auto auto;
}
.avatar-head {
    color: #717C9C;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.avatar-discription {
    color: #717C9C;
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    margin-top: 9px;
}
.title{
    justify-content: center;
    display: flex;
    color: #0849FF;
    font-family: "Source Sans pro";
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 65px;
}
.banner-img{
    width:100%;
    background-size: cover;
    height: 557px;
  }
.banner-img .container{
    justify-content: space-between;
    align-items: center;
}
.exploreMore{
    height: 40px;
    width: 200px;
    margin-top: 44px;
    border-radius: 25px;
    background-color: #0849FF;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
}
.exploreMore:hover{
    background-color: #0849FF;
    color: #FFFFFF;
}
.becomePartner {
    color:#fff;
    //  height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.shareguid  {
    justify-content: center;
    margin-bottom: 36px;
}
.shareguid h4{
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 28px;
}
.shareguid p{
  height: 72px;
  width: 375px;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
}  
.shareguid img{
    max-width: fit-content;
    border: 0;
}
.shareguid-content {
    margin-left: 27px;
}
.becomePartnerButton {
    box-sizing: border-box;
    height: 42px;
    width: 200px;
    border: 2px solid #FFFFFF;
    border-radius: 25.6px;
    margin-top: 52px;
}
.becomePartnerButton span:nth-child(1){
    height: 20px;
    width: 131px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
}
.mobile-partner-logo {
    display: none;
}
.desktop-partner-logo {
    display: block;
}
.arc-partner-padding {
    padding: 3vh 0;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .banner-content-wrap {
        width: auto;
    }
    .banner-img{
        padding: 5%;
        width: 100%;
        height: auto;
    }
    .preserve-and-provide {
        margin-bottom: revert;
        width: auto;
    }

    .home-header-container {
        margin-top: 0;
        margin-bottom: 5vh;
    
    }
}

@media only screen and (max-width: 991px) {
    .shareguid {
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 767px) {
    .logocontainer {
        display: none;
    }
    .hideShow {
        display: none;
    }
    .preserve-and-provide {
        width: 306px;
        font-size: 38px;
        line-height: 42px;
    }
    .banner-inner-img{
        width:  326px;
        height: 226px;
    }
    .discription{
        margin-top: 41px;
        margin-bottom: 55px;
    }
    .left {
        padding-right: 0px;
    }
    .right {
        padding-left: 0px;
    }
    .padding-bottom-110-and-21 {
        padding-bottom: 21px;
    }
    .avatar {
        flex-direction: column;
    }
    .avatar-write {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .avatar-img {
        width: 72px;
        height: 72px;
        margin: auto;
    }
    .avatar-head {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px;
        text-align: center;
    }
    .avatar-discription {
        color: #717C9C;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        margin-top: 16px;
        text-align: center;
        padding-bottom: 56px;
    }
    .title{
        font-size: 24px;
        line-height: 64px;
        margin-bottom: 25px;
    }
    .shareguid h4{
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 16px;
    }
    .shareguid p{
      width: auto;
    }
    .arc-partner-padding {
        padding: 60px 0;
    }
    .desktop-partner-logo {
        display: none !important;
    }
    .mobile-partner-logo {
        display: block !important;
    }
}

@media only screen and (max-width: 575px) {
    .banner-content-wrap {
        width: auto;
    }
    .banner-img{
        padding: 5%;
        width: 100%;
        height: auto;
    }
    .preserve-and-provide {
        margin-bottom: revert;
        width: auto;
    }
    .shareguid {
        flex-direction: column;
    }
    .shareguid-content {
        margin-left: auto;
    }
}

#tutorial {
    //margin:auto;
    width: 100%;
    /*border: yellow solid 3px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tutorial-document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tutorial-page {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    /*border: red solid 3px;*/
}

.tutorial-page > canvas {
    max-width: 100%;
    height: auto !important;
}


.OPlanContainer, .OPlanContainer * {
    font-family: var(--ion-font-family)
}
`;