import { IonCol, IonGrid, IonRow, IonSelectOption, IonInput, IonButton, IonLabel, IonItem, IonSelect } from '@ionic/react';
import { Formik, Field, Form } from "formik";
import React, { useEffect, useState } from 'react';


import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import {useTranslations} from '../../util/useTranslation';
import { AboutStyle } from './Onboardingstyle';

const OnboardingPage: React.FC = () => {

  const [co] = useTranslations('common');

  return (
    <div className='main' style={{ overflowY: 'scroll', height: '100vh' }}>
      <Header />
      <AboutStyle>
        <div className='project-wrap'>
          <div className='container wide'>
          {/*  <div className='about-this-wrap'>
              <div className='about-this-content-wrap'>
                <div>
                  <h2>J-Ark</h2>
                  <span>{co('header.duration')}</span>
                </div>
              </div>
              <div className='about-project-title'>
                <h4>{co('description.subheadline')}</h4>
                <div className='d-flex about-description'>
                  <div>
                    <p>{co('description.p1')}</p>
                    <p>{co('description.p2')}</p>
                  </div>
                  <div>
                    <p>{co('description.p3')}</p>
                    <p>{co('description.p4')}</p>
                  </div>
                </div>
              </div>
            </div>
        */} 
            <div>
            <iframe 
            src="https://docs.google.com/forms/d/e/1FAIpQLSeyz6fbOG-LVo_e3trtW4JV7244xybsYUof4ZPRq_GEjMQejQ/viewform?embedded=true&hl=en" 
            width="100%" height="1150" frameBorder="0" marginHeight={0} marginWidth={0}>Loading…</iframe>
{/*            <div className='partnership-with'>
              <div className='d-flex container wide ion-justify-content-start '>
               <Formik
                initialValues={{
                  color: null,
                  dataset_name: null,
                  full_name: null,
                  email: null
                }}
                onSubmit={values => {
                  alert(JSON.stringify(values, null, 2));
                }}
              >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                      <IonItem>
                        <IonLabel>Email</IonLabel>
                        <IonInput name="email" required={true} value={formikProps.values.email} onIonChange={formikProps.handleChange}></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Full Name</IonLabel>
                        <IonInput name="full_name" required={true} value={formikProps.values.full_name} onIonChange={formikProps.handleChange}></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Dataset Name</IonLabel>
                        <IonInput name="dataset_name" required={true} value={formikProps.values.dataset_name} onIonChange={formikProps.handleChange}></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Select Color</IonLabel>
                        <IonSelect
                          name="color"
                          value={formikProps.values.color}
                          onIonChange={formikProps.handleChange}
                        >
                          <IonSelectOption value="brown">Brown</IonSelectOption>
                          <IonSelectOption value="blonde">Blonde</IonSelectOption>
                          <IonSelectOption value="black">Black</IonSelectOption>
                          <IonSelectOption value="red">Red</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <p className="error">
                        {formikProps.touched.color && formikProps.errors.color}
                      </p>
                      <IonButton type="submit">Submit</IonButton>
                    </form>

                )}
              </Formik>
                                 
              </div>
            </div>
            */}
          </div>
         </div>
        </div>
      </AboutStyle>
      <Footer />
    </div>
  );
};

export default OnboardingPage;