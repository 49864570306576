import styled from "styled-components";

export const AboutStyle = styled.div`
--body{
    overflow:hidden;
}
.about-this-wrap{
    padding: 109px 0 60px;
    border-bottom: 1px solid #7782A0;
}
.about-this-content-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 80px;
}
.about-this-content-wrap h2{
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 26px;
}
.about-this-content-wrap span{
    color: #717C9C;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.29px;
    line-height: 16px;
    text-transform: uppercase;
}
.about-project-title h4{
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
}
.about-project-title .about-description{
    justify-content: space-between;
    width: 100%;
    column-count: 2;
    column-span: all;
    column-gap: 5vw;
}
.about-project-title p{
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
}
.partnership-with{
    padding: 60px 0 108px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.partnership-with img{
    max-width: 75%;
}
.partnership-with span{
    height: 19px;
    width: 250px;
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
}
.partnership-img-wrap{
    width:81%;
}
.partner-logo-wrap {
    max-width: 10vw;
    padding-left: 0px;
    margin: 15px 0px;
}
.exptitle{
    height: 40px;
    width: 710px;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
}
.search-explore-card-wrap{
    margin: 61px auto 80px;
}
.padding-left-0 {
    padding-left: 0px;
}
.experiences{
    color: #717C9C;
    padding-bottom: 35px;
}
.pagination{
    display: flex;
    justify-content: center;
    margin-bottom: 60px
}
.pagination li button{
    color: #717C9C;
}
.pagination li + li {
    margin-left: 0;
}

@media only screen and (max-width: 1199px) {
    .partnership-with{
        padding: 50px 0 85px;
        flex-direction: column;
        align-items: flex-start;
    }
    .margin-bottom-32{
        margin-bottom: 32px;
    }
    .partnership-img-wrap{
        width:100%;
    }
}

@media only screen and (max-width: 575px) {
    .about-this-wrap{
        padding: 5px 0 50px;
    }
    .about-this-content-wrap{
        margin-bottom: 50px;
    }
    .about-this-content-wrap h2{
        font-size: 32px;
        margin-bottom: 10px;
    }
    .about-this-content-wrap span{
        font-size: 16px;
        letter-spacing: 1.14px;
    }
    .about-project-title h4{
        font-size: 18px;
        line-height: 24px;
    }
    .about-project-title .about-description{
        flex-direction: column;
    }
    .about-project-title p{
        font-size: 14px;
        line-height: 24px;
    }
    .exptitle{
        font-size: 18px;
        line-height: 24px;
    }
    .search-explore-card-wrap{
        margin: 50px auto 55px;
    }
}
`;