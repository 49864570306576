import { IonCol, IonGrid, IonRow } from '@ionic/react';
import Link from '@material-ui/core/Link';
import React, { useEffect } from 'react';
import {useRouteMatch } from 'react-router-dom';

import archiveImage1 from '../../assets/archive/archive-image-1.png';
import archiveImage2 from '../../assets/archive/archive-image-2.png';
import archiveImage3 from '../../assets/archive/archive-image-3.png';
import archiveImage4 from '../../assets/archive/archive-image-4.png';
import archiveImage5 from '../../assets/archive/archive-image-5.png';
import linkIcon from '../../assets/icon/link-icon.svg';
import locationIcon from '../../assets/icon/location-icon.svg';
import ArchiveLogo from '../../assets/logo/archive-logo.png';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import {useTranslations, translateArchive} from '../../util/useTranslation';
import { getSingleArchive, cmsImage2Url } from '../../util/API';
import { SingleArchivestyle } from './SingleArchivestyle';

const SingleArchive: React.FC = () => {
	const [co] = useTranslations('common');
	const { params } = useRouteMatch<{ id?: string }>();
	const [archiveData, setArchiveData] = React.useState<any>([])


	useEffect(() => {
		const fetchArchiveData = async () => {
			console.log("PARAMS: " + JSON.stringify(params));
			const results: any = await getSingleArchive(params?.id);
		  console.log("RESULTS: " + JSON.stringify(results));
		  if (results.data.data) {
			setArchiveData(results.data.data[0]);
		  }
		};
		fetchArchiveData();
	  }, []);

	let trData = translateArchive(archiveData);  
	return (
		<div className='main' style={{ overflowY: 'scroll', height: '100vh' }}>
			<Header />
			<SingleArchivestyle>
				<div className='project-wrap'>
					<div className='container wide'>
						<div className='archive-this-wrap'>
							<div className='archive-this-content-wrap'>
								<div>
									<h2>{trData?.title}</h2>
									<div className='d-flex ion-align-items-center'>
										<img src={locationIcon} alt="Location Icon" className='locationIcon' />
										<span>{trData?.country}</span>
									</div>
								</div>
							</div>
							<div className='d-flex archive-description'>
								<IonGrid>
									<IonRow style={{ columnGap: '7%' }}>
										<IonCol size='12' sizeLg='8' className='relative-line padding-archive-description'>
											<p>{trData?.description}</p>
											{trData?.roda_url ?
											<div className='d-flex'>
												<img src={linkIcon} alt="Link Icon" />
												<Link className='linkName' href={trData?.roda_url} target="_blank">{co('roda_url')}</Link>
											</div> : null
											}
											{trData?.europeana_url ?
											<div className='d-flex'>
												<img src={linkIcon} alt="Link Icon" />
												<Link className='linkName' href={trData?.europeana_url} target="_blank">{co('europeana_url')}</Link>
											</div> : null
											}
				
										</IonCol>
										<IonCol className='archive-detail padding-archive-description'>
											<div className='created-partner'>
												<span>{co('single_archive.partnership')}</span>
												<img src={cmsImage2Url(trData?.provider?.logo)} alt="Archive Logo" style={{ width: "50%", height: "auto"}} />
											</div>
											<div>
												<span>part of the <a href="/about"> J-Ark </a></span>
											</div>
										</IonCol>
									</IonRow>
								</IonGrid>
							</div>
						</div>
						<div className="archive-image">
							<IonGrid>
							{trData?.images?.length > 0 ?
								<IonRow>
										<IonCol size='12' sizeXl='6' sizeLg='12' className='padding-sides-image'>
											<img src={cmsImage2Url(trData?.images[0]?.directus_files_id)} alt="Achive Image 1" />
										</IonCol>
									<IonCol className='padding-sides-image'>
									  {trData?.images?.length > 1 ?
										<IonRow>
											<IonCol size='12' sizeXl='6' sizeLg='6' sizeMd='6' className='padding-top-0 padding-left-0'>
												<img src={cmsImage2Url(trData?.images[1]?.directus_files_id)} alt="Archive Image 2" />
											</IonCol>
											<IonCol size='12' sizeXl='6' sizeLg='6' sizeMd='6' className='padding-top-0 padding-left-0 .padding-left-2-3'>
												<img src={cmsImage2Url(trData?.images[2]?.directus_files_id)} alt="Archive Image 3" />
											</IonCol>
										</IonRow>
										: ""
									  }
									{trData?.images?.length > 3 ?
										<IonRow>
											<IonCol size='12' sizeXl='6' sizeLg='6' sizeMd='6' className='padding-top-0 padding-left-0'>
												<img src={cmsImage2Url(trData?.images[3]?.directus_files_id)} alt="Archive Image 4" />
											</IonCol>
											<IonCol size='12' sizeXl='6' sizeLg='6' sizeMd='6' className='padding-top-0 padding-left-0 .padding-left-2-3'>
												<img src={cmsImage2Url(trData?.images[4]?.directus_files_id)} alt="Archive Image 5" />
											</IonCol>
										</IonRow>
										: ""
									}	
									</IonCol>
								</IonRow>
								: ""
							}
							</IonGrid>
						</div>
					</div>
				</div>
			</SingleArchivestyle>
			<Footer />
		</div>
	)
};

export default SingleArchive;