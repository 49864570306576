import { IonCol, IonGrid, IonRow } from '@ionic/react';

import OpenLink from '../../assets/avatar/open-link.svg';
import Reliable from '../../assets/avatar/reliable.svg';
import Standard from '../../assets/avatar/standard.svg';
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import {useTranslations} from '../../util/useTranslation';
import { HomePage as Homestyle } from '../Home/Homestyle';
import { Plan, PlanProps } from "pricing-table-react";

const plansProps: PlanProps[] = [
  {
    href: "/onboarding",
    price: "0",
    title: "Free",
	advantages: [
		{
		  text: "2 datasets",
		},
		{
		  text: "1 Gb of storage",
		},
		{
		  text: "700 images, 500 mp3 files",
		},
	  ],
	duration: "month*",  
    titleDescription: "Individuals",
  },
  {
    href: "/onboarding",
    price: "5",
    title: "Small",
    advantages: [
      {
        text: "5 datasets",
      },
      {
        text: "10 Gb of storage",
      },
	  {
		text: "3,500 images, 2,500 mp3 files",
	  },
    ],
    duration: "month*",
    titleDescription: "Small archives",
  },
  {
    href: "/onboarding",
    price: "10",
    title: "Medium",
    advantages: [
      {
        text: "15 datasets",
      },
      {
        text: "50 Gb of storage",
      },
	  {
		text: "17,000 images, 15,000 mp3 files",
	  },
    ],
    duration: "month*",
    highlightText: "Popular",
    titleDescription: "Small-to-medium archives",
  },
  {
    href: "/onboarding",
    price: "50",
    title: "Big",
    advantages: [
      {
        text: "100 datasets",
      },
      {
        text: "500 Gb of storage",
      },
	  {
		text: "170,000 images, 150,000 mp3 files",
	  },
    ],
    duration: "month*",
    titleDescription: "Big archives",
  }
];

const Pricing: React.FC = () => {
	const [co] = useTranslations('pricing');

	return (
		<div className='main' style={{ overflowY: 'scroll', height: '100vh' }}>
			<Header />
			<Homestyle>
				<div className='container'>
					<span className='title'>{co('title')}</span>
					<span className='avatar-discription'>{co('subtitle')}</span>

					<Plan BackColor='var(--ion-color-primary)' Currency="€" Plans={plansProps} />
					<span className='avatar-discription'>{co('footer')}</span>

				</div>
			</Homestyle>
			<Footer />
		</div>
	);
}

export default Pricing;