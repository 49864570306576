import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { NavLink } from 'react-router-dom';
import logoImg from '../../assets/logo/j-arc-logo-blue.svg';
import './HeaderIconstyle.css';
import { HeaderPage } from './Headerstyle';
import {useTranslations, SUPPORTED_LANGS, DEFAULT_LANG, currentLanguage} from '../../util/useTranslation';
import Select from 'react-select';
import i18n from 'i18next';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'flex-end'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      display: 'flex',
      flexDirection: 'column',
      alignItems: ' flex-start',
      padding: '41px 20px 69px',
      borderBottomLeftRadius: '20px',
      width: '253px',
      Height: '333px'
    }
  })
);

const Header: React.FC<{hideLogo?: boolean, logo?: any}> = ({hideLogo = false, logo=logoImg}) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [co, lang, setLang] = useTranslations('common');

  const menuItems = [[co('menu.home'), '/'], [co('menu.how_it_works'), '/how-it-works'],
  [co('menu.archives'), '/archives'], [co('menu.pricing'), '/pricing'],
  [co('menu.sign_up'), '/onboarding'], [co('menu.about'), '/about']]



  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onLanguageChange = (value, type) => {
    localStorage.setItem('language', value.value);
    let selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage != null) {
      i18n.changeLanguage(selectedLanguage);
      setLang(selectedLanguage);
    }

    console.log("Setting language: " + JSON.stringify(value.value));
  }

  const lang2option = (lang) => {
    if (lang == null) {
      lang = DEFAULT_LANG;
    }
    return {"value": lang, "label": lang?.toUpperCase()}
  }


  const langSelect = () => {
    return (
      <Select className="navheader" 
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      marginLeft: '40px'
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                  }}
                  menuPlacement='bottom'
                  isSearchable={false}
                  onChange={onLanguageChange}
                  value={lang2option(currentLanguage())}
                  options={SUPPORTED_LANGS.map((l) => { return lang2option(l)})}
                  unstyled
                  //styles={colourStyles}
                  /*components={{
                    IndicatorSeparator: () => null,Option: IconOption
                  }} */
                />  
    )
  }

  return (
    <div>
      <HeaderPage>
        <div className="container wide header-container">
          <div className="header">
            <div className="headerlogocontainer">
              { !hideLogo ?
                <a href="/home">
                  <img src={logo} alt="J-Ark" />
                </a> : null
              }
            </div>
            {/* Desktop view */}
            <div className="desktopview d-flex ion-align-items-center">
              {menuItems.map((item: any, i: any) => {
                  return (<NavLink className="navheader" to={item[1]} style={{ marginLeft: '40px' }}>
                            {item[0]}
                          </NavLink>) 
                })
            }
            {langSelect()} 
            </div>

            {/* Mobile view */}
            <div className="mobileview d-flex ion-align-items-center">

              <div>
                
                <button className="bar-icon" type="button" onClick={handleOpen}>
                  <MenuIcon />
                </button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <div onClick={handleClose} className="clear-icon">
                        <ClearIcon />
                      </div>
                      {menuItems.map((item: any, i: any) => {
                        return (<NavLink className="navheader" to={item[1]} style={{ marginLeft: '40px' }}>
                                  {item[0]}
                                  <span className='line'></span>
                                </NavLink>
                                ) 
                        })
                      }
                      {langSelect()} 

                    </div>
                  </Fade>
                </Modal>
              </div>
            </div>

          </div>
        </div>
      </HeaderPage>
    </div>
  );
};

export default Header;