import React from 'react';
import { useLocation } from 'react-router-dom';

const Root: React.FC<{ path: string }> = ({ path }) => {
  const location = useLocation();
  return (
    <div></div>
  );
};

export default Root;
