import styled from "styled-components";

export const SingleArchivestyle = styled.div`
--body {
    overflow: hidden;
};
--ion-grid-padding: 0px;

.archive-this-wrap{
    padding: 109px 0 58px;
}
.archive-this-content-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 56px;
}
.archive-this-content-wrap h2{
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 29px;
}
.archive-this-content-wrap span{
    color: #717C9C;
    font-size: 24px;
    font-weight: 600;
    line-height: 43.2px;
    margin-left: 24px;
}
.locationIcon {
    width: 24px;
    height: 33px;
}
.archive-description {
    width: 100%;
    column-count: 2;
    column-span: all;
}
.archive-description p {
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0;
    margin: 0px;
}
.archive-description span {
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 1.29px;
    margin: 0px;
}
.relative-line {
    display: flex;
    flex-direction: column;
    row-gap: 28px;
}
.padding-archive-description {
    padding: 0px;
}
.linkName {
    text-decoration-line: underline;
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    color: #0849ff;
    margin-left: 8px;
}
.archive-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    row-gap: 68px;
}
.created-partner {
    display: flex;
    flex-direction: column;
    row-gap: 22px;
}
.archive-image {
    padding-bottom: 133px;
}
.column-gap {
    column-gap: 30px;
}
.gap {
    row-gap: 30px;
    flex-direction: column;
}
.padding-top-0 {
    padding-top: 0px !important;
}

@media only screen and (max-width: 1199px) {
    .padding-sides-image {
        padding: 5px 0px;
    }
}

@media only screen and (max-width: 991px) {
    .archive-detail {
        margin-top: 55px;
        row-gap: 35px;
    }
}

@media only screen and (max-width: 768px) {
    .padding-left-2-3 {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .archive-image {
        padding-bottom: 75px;
    }
    .padding-left-0 {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 576px) {
    .archive-this-wrap{
        padding: 30px 0 45px;
    }
    .archive-this-content-wrap {
        margin-bottom: 32px;
    }
    .archive-this-content-wrap h2 {
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 22px;
    }
    .archive-this-content-wrap span {
        font-size: 16px;
        line-height: 26px;
        font-weight: bold;
        margin-left: 7px;
    }
    .locationIcon {
        width: 14px;
        height: 20px;
    }
    .archive-description p {
        font-size: 16px;
        line-height: 24px;
    }
    .archive-description span {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1px;
    }
}
`;