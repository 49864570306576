
import React, { useState, useRef } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import {useTranslations} from '../../util/useTranslation';
import { HomePage as Homestyle } from '../Home/Homestyle';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const TermsOfUse: React.FC = () => {
	const [co] = useTranslations('how_it_works');

	const [numPages, setNumPages] = useState(null);
	const pageRefs = useRef({});

	
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
	function onItemClick({ pageNumber }) {
		console.log("Page changed to " + pageNumber)
		pageRefs.current[pageNumber].scrollIntoView({ behavior: 'smooth' });

	  }


	return (
		
		<div className='main' style={{ overflowY: 'scroll', height: '100vh' }}>
			<Header />
			<Homestyle>


				<div className='container'>
					<span className='title'>Terms Of Use</span>					
					<div>
			
						<Document className={"tutorial-document"} file={`./assets/terms-of-use.pdf`} 
									onLoadSuccess={onDocumentLoadSuccess}
									onItemClick={onItemClick}>
							{Array.from(new Array(numPages), (el, index) => (
								<div id={`page${index}`} style={{textAlign: "center"}}>
									<Page className={"tutorial-page"} inputRef={el => { pageRefs.current[index] = el; }} height={1500} key={`page_${index + 1}`} pageNumber={index + 1} />
								</div>
							))}						
						</Document>
					</div>
				</div>
			</Homestyle>
			<Footer />
		</div>
	);
}

export default TermsOfUse;