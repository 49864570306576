import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const { REACT_APP_AUTH0_DOMAIN = '', REACT_APP_AUTH0_CLIENT_ID = '' } =
  process.env;
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={'Loading...'}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();

reportWebVitals();
