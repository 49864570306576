import styled from "styled-components";

export const ArchiveDemostyle = styled.div`
--ion-grid-padding: 0px;

.margin-bottom-19 {
    margin-bottom: 19px;
}
.item-row {
    background-color: #ffffff;
    border-radius: 15px;
}
.padding-left-17 {
    padding: 17px;
}
.item-img-start {
    width: auto;
    height: 135px;
    margin: 16px 0px 16px;
}
.middle-content h4,p {
    margin: 0px;
    padding: 0px;
}
.partner-project-name {
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 27px;
    text-transform: uppercase;
}
.jewish-archive {
    color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
}
.jewish-archive .item-title-link {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 200px;
    text-decoration: none;
}
.item-details-description {
    margin-top: 16px;
    height: auto;
}
.lining {
    border-left: 2px solid #7782A0;
    margin: 30px 49px 30px 0px;
    height: 100px;
}
.item-img-end {
    width: 80px;
    height: 65px;
    margin: 48px auto 47px;
}
.border-left {
    border-left: 1px solid black;
    align-self: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .item-details-description {
        height: 8vw;
    }
}

@media only screen and (max-width: 1199px) {
    .padding-sides-0 {
        padding: 5px 0px;
    }
}

@media only screen and (max-width: 991px) {
    .padding-left-17{
        margin-left: 0px;
    }
    .item-img-start {
        width: -webkit-fill-available;
        // height: auto;
        height: 190px;
        object-fit: cover;
    }
    .item-img-end {
        width: 80px;
        height: 65px;
        margin: 13px auto 29px 0px;
    }
    .item-details-description {
        height: auto;
    }
    .border-left {
        border-left: 0px;
    }
}
`;