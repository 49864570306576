import { IonApp } from '@ionic/react';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AboutPage from './pages/About/About';
import Archives from './pages/Archives/Archives';
import Home from './pages/Home/Home';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import OnboardingPage from './pages/Onboarding/Onboarding';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import Pricing from './pages/Pricing/Pricing';
import SingleArchive from './pages/SingleArchive/SingleArchive';
import GlobalStyle from './components/styled/GlobalStyle';
import Root from './pages/Root';
import './theme/variables.scss';
import 'dotenv/config';


const App: React.FC = () => {
  return (
    <CookiesProvider>
      <IonApp>
        <GlobalStyle />
        <BrowserRouter>
          <Switch>
            {/* <Route path={`/signin`} render={() => <Redirect to='/app/dashboard' />}  /> */}
            <Route path='/home' component={Home} />
            <Route path={`/about`} component={AboutPage} />
            <Route path={`/how-it-works`} component={HowItWorks} />
            <Route path={`/pricing`} component={Pricing} />
            <Route path={`/onboarding`} component={OnboardingPage} />
            <Route path={`/archives`} component={Archives} />
            <Route path={`/terms-of-use`} component={TermsOfUse} />
            <Route path={`/archive/:id+`} component={SingleArchive} />
            <Route path='/app' render={() => <Root path='/app' />} />
            <Route render={() => <Redirect to='/home' />} />
          </Switch>
        </BrowserRouter>
      </IonApp>
    </CookiesProvider>
  );
};

export default App;
