import React from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {useTranslations} from '../util/useTranslation';

const Footer = (props: any) => {
  const { hideForRoute } = props;
  const [co] = useTranslations('common');
  let history = useHistory();
  let id = history.location.pathname.split('/')[3];

  return (
    <div>
      {
        hideForRoute && hideForRoute.indexOf(history.location.pathname) > -1 || history.location.pathname == `/experience/${id}` || history.location.pathname == `/search-explore/${id}` ? '' :
          <Styled>
            <div className='container wide d-flex ion-justify-content-center responsive'>
              <div className='copy'>
                &copy;  {co('footer.copy')}
              </div>
       <NavLink to='terms-of-use' className='tos'>
                {co('footer.link')}
              </NavLink>
      
            </div>
          </Styled>
      }
    </div>
  );
};

const Styled = styled.footer`
min-height: 70px;
background-color: var(--ion-color-secondary);
color: #F7F7F7;
display: flex;
align-items: center;
margin-top: auto;
bottom: 0;
width: 100%;

a {
  color: inherit;
}
.tos {
  margin-left: 43px !important;
}

@media only screen and (max-width: 767px) {
  font-size: 12px;
  line-height: 31px;
  letter-spacing: 0.33px;
  .responsive {
    flex-direction: column;
    align-items: center;
  }
  .tos {
    margin-left: 0px !important;
  } 
}
`;

export default Footer;