import { IonCol, IonGrid, IonRow } from '@ionic/react';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import PartnerLogo from '../../assets/logo/europeana-logo.svg';
import ArchiveDetails from '../../components/ArchiveDetails/ArchiveDetails';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import { getArchives, getPartners, cmsImage2Url } from '../../util/API';
import {useTranslations} from '../../util/useTranslation';
import { AboutStyle } from './Aboutstyle';

const ProjectPage: React.FC = () => {
  const [archiveData, setArchiveData] = React.useState([])
  const [partnersData, setPartnersData] = React.useState<any>([])

  const [co] = useTranslations('common');
  const [page, setPage] = useState<number>(1);
  const [allData, setAllData] = useState<any>([]);
  const itemsPerPage = 4;

  const onPageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setAllData(archiveData.slice((page - 1) * itemsPerPage, page * itemsPerPage))
  }, [archiveData, page]);

  useEffect(() => {
    const fetchArchiveData = async () => {
      const results: any = await getArchives();
      if (results.data.data.length) {
        setArchiveData(results.data.data);
        setAllData(results.data.data);
      }
    };

    const fetchPartnersData = async () => {
      const results: any = await getPartners();
      if (results.data.data.length) {
        setPartnersData(results.data.data);
      }
    }
    fetchArchiveData();
    fetchPartnersData();
  }, []);

  return (
    <div className='main' style={{ overflowY: 'scroll', height: '100vh' }}>
      <Header />
      <AboutStyle>
        <div className='project-wrap'>
          <div className='container wide'>
            <div className='about-this-wrap'>
              <div className='about-this-content-wrap'>
                <div>
                  <h2>J-Ark</h2>
                  <div className='about-project-title'><p>{co('header.duration')}</p></div>
                </div>
              </div>
              <div className='about-project-title'>
                <h4>{co('description.subheadline')}</h4>
                <div className='d-flex about-description'>
                  <div>
                    <p>{co('description.p1')}</p>
                    <p>{co('description.p2')}</p>
                  </div>
                  <div>
                    <p>{co('description.p3')}</p>
                    <p>{co('description.p4')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='partnership-with'>
              <div className='margin-bottom-32'>
                <span>{co('createdParterner')}</span>
              </div>
              <div className='partnership-img-wrap d-flex ion-justify-content-center '>
                <IonGrid className='padding-left-0'>
                  <IonRow>
                  {partnersData.map((data: any, i: any) => {
                    console.log(JSON.stringify(data));
                    return (
                      <IonCol key={data?.id} size='6' sizeXl='2' className='partner-logo-wrap'>
                        <a href={data?.url} target="_blank">
                          <img src={cmsImage2Url(data?.logo?.id)} alt={data?.name} />
                        </a>
                      </IonCol>
                    )
                  }) }
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>
          <div className='container wide'>
            <span className='exptitle'>{co('archivesProject')}</span>
            <div className='search-explore-card-wrap'>

              <ArchiveDetails data={allData} />

            </div>
            <div className='pagination-wrap'>
              {allData.length ? (
                <div className='d-flex ion-justify-content-center'>
                  <span className='experiences'>
                    Displaying {Math.min(itemsPerPage, archiveData.length)} out of {archiveData.length} archives
                  </span>
                </div>
              ) : null}
              <div className='pagination-section container'>
                <div className='pagination'>
                  {allData.length ? (
                    <Pagination
                      hidePrevButton={page == 1 ? true : false}
                      hideNextButton={
                        page == Math.ceil(archiveData.length / itemsPerPage)
                          ? true
                          : false
                      }
                      onChange={onPageChange}
                      count={Math.ceil(archiveData.length / itemsPerPage)}
                      page={page}
                      defaultPage={1}
                      color='primary'
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AboutStyle>
      <Footer />
    </div>
  );
};

export default ProjectPage;